<script>
//import Policy from '@/components/misc/Policy.vue'
export default {
  name: "FooterUtils",
};
</script>

<template>
  <v-row justify="space-between" align="center" class="mx-0 mt-1">
    <h4 class="grey--text">
      Yeeko Inteligencia Colectiva SAPI de CV.  {{ new Date().getFullYear() }}
    </h4>
    <h5 class="grey--text">
      <v-btn text color="grey darken-1">Aviso de Privacidad</v-btn>
    </h5>
    <v-dialog v-model="show_policy_and_terms" max-width="1300" v-if="false">
			<Policy/>

		</v-dialog>
  </v-row>
</template>