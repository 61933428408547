import router from '@/router'
import JwtService from "@/common/jwt.service";
import { TOKEN_SITE } from "@/common/config";
import cookies from 'vue-cookies'
import { getFbLoginStatus, fbLogout } from "@/components/dashboard/login/helpers"
import ApiService from "./base";


const state = {
  user_details: {},
  is_authenticated: !!JwtService.getToken(),
  first_line: '',
  how_it_works: 'https://m.me/yeekoapp?ref=:1255',
  second_line: '',
  has_perm: false,
  next_url: undefined,
  show_select: false,
  exists_page_premium: false,
  has_pages: false,
  has_pages_premium: false,
  is_loading: false,
  is_logging_in: true,
  is_logging_wv: true,
  status_logging_wv: 'vacío',
  needs_perm: false,
  key_for_login: undefined,
  // de momento es false para uso de demo
  // la idea es que se evalúe si tiene páginas a las que aún
  // no ha conectado y tiene los permisos
  can_link_pages: false,
  has_available_pages: false,
  available_pages: [],
  login_simple: true,
  //añadir esto en otro módulo
  messagesInfo: {},
  //borrar en cuanto sea posible (9/08/2019)
  is_special_recover: false,
  is_logged: false,
};

const getters = {
  currentUser(state) {
    return state.user_details;
  },
  is_authenticated(state) {
    return state.is_authenticated;
  },
}


const mutations = {

  SET_AUTH(state, user) {
    state.is_authenticated = true;
    state.is_logging_in = false;
    state.user_details = user;
    state.is_logged = Date.now();
    state.errors = {};
    state.has_basic_login = true
    JwtService.saveToken(state.user_details.token);
    // Setea los valores del userId de la cookie de Google Analytics
    //setGAUserId(user['id'])
  },
  PURGE_AUTH(state) {
    state.is_authenticated = false;
    state.user_details = {};
    state.errors = {};
    state.is_loading = false;
    state.has_basic_login = false;
    state.available_pages = [];
    state.has_available_pages = false;
    state.key_for_login = undefined;
    state.facebook_pages_count = 0;
    JwtService.destroyToken();
  },
  SET_KEY(state, key) {
    state.key_for_login = key
  },
  SET_PAGES(state, user){
    try{
      let pages = user["pages_premium"]
      let available_pages = pages.filter(el => el.page_status === 'Available')
      state.available_pages = available_pages
      state.has_available_pages = Boolean(available_pages.length);
      state.facebook_pages_count = pages.filter(el => el.page_status !== 'Demo').length
    }
    catch(error){
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },
  SET_LOGIN_SIMPLE(state,is_simple){
    state.login_simple = is_simple;
  },
  UPDATE_PAGE_IN_PAGES(state, [available_pages, pages_premium]) {
    state.available_pages = available_pages;
    state.user_details['pages_premium'] = pages_premium;
  },
  // para actualizar una nueva página a la lista de premium
  UPDATE_PAGES_PREMIUM(state, demo_details) {
    state.user_details['pages_premium'].push(demo_details)
  },
  SET_USER_DETAILS(state, data) {
    state.user_details = data;
  },
  SET_IS_LOADING(state, bool) {
    state.is_loading = bool
  },
  SET_IS_LOGGING_IN(state, value) {
    state.is_logging_in = value
  },
  SET_IS_LOGGING_WV(state, is_logging_wv) {
    state.is_logging_wv = is_logging_wv
  },
  SET_STATUS_LOGIN_WV(state, status_logging_wv){
    state.status_logging_wv = status_logging_wv
  },
  SET_NEXT_URL(state, need_set) {
    let route_name = router.currentRoute.name
    // state.next_url = undefined
    if (need_set && route_name !== 'Login' && route_name !== 'Dashboard')
      state.next_url = router.currentRoute.path
    else if(!need_set)
      state.next_url = undefined
  },
  SET_INITIAL_COLLECT(state, bool) {
    state.user_details.current_page_details.initial_collect = bool
  },
}


const actions = {
  SET_HEADER(){
    let token = JwtService.getToken()
    if (token){
      ApiService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
  },
  RESET_ALL({commit}){
    commit('RESET')
    commit('PURGE_AUTH')
    router.replace({ name: 'Login' });
  },
  CHECK_AUTH_SIMPLE({dispatch, commit, state}){
    console.log("CHECK_AUTH_SIMPLE")
    let checkFBstatus = function(){
      //console.log('1: ', !!window.FB)
      return new Promise (resolve => {
        //Si no existe FB, se llama a un proceso asíncrono
        if (!window.FB){
          dispatch("LOAD_FB_SDK")
          console.warn('|FBNotAvaiable')
          return resolve({waitFB:true})
        }
        //Si estaba cargado, se espera respuesta del login forzado.
        else {
          dispatch("FB_LOGIN_FORCED")
            .then((resp)=>resolve(resp));
        }
      });
    }
    commit("SET_IS_LOGGING_IN", true)
    commit("SET_KEY", router.currentRoute.query.key)
    return new Promise (resolve => {
      //primero comprobamos que exista token de usuario
      if (JwtService.getToken()) {
        // console.log("hay token")
        let last_login = state.is_logged
        //Si tiene un login del último día, no hacemos de nuevo el login
        if (last_login && last_login + (3600*48*1000) > Date.now()){
          console.log("last_login", last_login)
          dispatch("REDIRECT_LOGIN")
          dispatch("LOAD_FB_SDK", false);
          commit("SET_IS_LOGGING_IN", false)
          return resolve()
        }
        //Si hay token de usuario, obtiene su info
        dispatch("GET_CURRENT_USER")
          .then((dataUser) => resolve(dataUser))
          //Si hay cualquier error se intenta loguear por facebook
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err)
            checkFBstatus()
          })
            .then((response) => resolve(response));
      } else{
        console.log("No hay token")
        //Si no hay token, directamente intenta generar un login con Facebook
        return checkFBstatus().then((response)=>{
          console.log("response en checkFBstatus", response);
          commit("SET_IS_LOGGING_IN", false)
          return resolve(response)
        });
      }
    })
  },
  LOAD_FB_SDK({dispatch}, login_forced=true) {
    let appId = process.env.VUE_APP_FACEBOOK_ID;
    // console.log("window in LOAD_FB_SDK", window)
    // let appId = "778287658900971";
    let version = 'v18.0';
    window.fbAsyncInit = function () {
      // window.fbAsyncInit = function () { // eslint-disable-line func-names
      // console.log("fbAsyncInit")
      // eslint-disable-next-line no-undef
      console.log("fbAsyncInit", FB)

      FB.init({
        appId: appId,
        xfbml: true,
        version: version,
        cookie: true,
        // autoLogAppEvents: true
      });
      // eslint-disable-next-line no-undef
      FB.AppEvents.logPageView();
      FB.getLoginStatus(function(response) {
        console.log("response getLoginStatus", response)
      });
      if (login_forced)
        dispatch("FB_LOGIN_FORCED")
      return true
    };
    (function (d, s, id) { // eslint-disable-line func-names
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)){
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      // js.src = 'https://connect.facebook.net/es_US/sdk.js';
      js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"
      js.crossorigin = 'anonymous';
      // js.async = true;
      // js.defer = true;
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  },
  LOAD_PLUGIN({dispatch}) {
  // LOAD_PLUGIN_SDK({dispatch}, login_forced=true) {
    let appId = process.env.VUE_APP_FACEBOOK_ID;
    let version = 'v18.0';
    window.fbAsyncInit = function () {
      FB.init({
        appId: appId,
        xfbml: true,
        version: version,
        cookie: true,
      });
      console.log("fbAsyncInit", FB)
      console.log("CustomerChat", FB.CustomerChat)
      FB.CustomerChat.show();
      FB.CustomerChat.showDialog();

      return true
    };
    // (function (d, s, id) { // eslint-disable-line func-names
    //   const fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)){
    //     return;
    //   }
    //   const js = d.createElement(s);
    //   js.id = id;
    //   js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // }(document, 'script', 'facebook-jssdk'));

    // let chatJsUrl = "https://cdn-yeeko.s3.us-west-2.amazonaws.com/facebook/whatsapp.js"
    let chatJsUrl = "https://ngrok.yeeko.org/media/plugin/whatsapp.js"
    console.log("window in LOAD_CHAT_JS", window)

    window.chatAsyncInit = function () {
      // Initialize your chat here
      // For example:
      // Chat.init({
      //   param1: value1,
      //   param2: value2,
      // });
      return true
    };
    (function (d, s, id) { // eslint-disable-line func-names
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)){
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = chatJsUrl;
      // js.type = 'text/javascript';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'chat-plugin'));
  },
  //FB_LOGIN_FORCED({dispatch}, need_redirect=true) {
  FB_LOGIN_FORCED({dispatch}) {
    console.log("FB_LOGIN_FORCED")
    return new Promise (resolve => {
      //Se intenta obtener el login status
      getFbLoginStatus().then((responseFb) => {
        //si el login status es auth, se realiza un login con Yeeko
        if (responseFb['authResponse'])
          dispatch("YEEKO_LOGIN", responseFb)
        else
          return resolve(dispatch("HAS_NOT_LOGGED",'fbNotLogged'))
      }).catch((err)=>{
        // eslint-disable-next-line no-console
        console.error(err)
        return resolve(dispatch("HAS_NOT_LOGGED", 'loginStatusNotAvaiable'))
      })
    })
  },
  LOGIN_FROM_FACEBOOK({dispatch, commit}, credentials=false){
    commit("SET_IS_LOGGING_IN", true)
    return new Promise (resolve => {
      if (credentials)
        return resolve(dispatch("YEEKO_LOGIN", credentials))
      else{
        //console.log("no hay credenciales")
        dispatch("NOTIFY_USER", `No se realizó un login exitoso con Facebook, 
          por favor, inténtalo de nuevo`);
        return resolve(dispatch("HAS_NOT_LOGGED", 'noCredentials'))
      }
    })
  },
  ASIGN_PERM_FACEBOOK({commit, dispatch}, credentials){
    commit("SET_LOGIN_SIMPLE", false)
    dispatch("LOGIN_FROM_FACEBOOK", credentials)
  },
  LOGIN_MAIL({commit, dispatch}, params){
    return new Promise((resolve) => {
      //ApiService.post('/login/', params)
      ApiService.post('/login/', params)
        .then(({data}) => {
          commit("PURGE_AUTH");
          commit("SET_AUTH", data)
          dispatch('YEEKO_LOGIN_MAIL', data)
          return data
        })
        .catch(err =>{
          // eslint-disable-next-line no-console
          console.error(err)
          return resolve({error:err})
        })
    })
  },
  REGISTER_MAIL({commit, dispatch}, params){
    return new Promise((resolve) => {
      ApiService.post('/register/', params)
        .then(({data}) => {
          commit("PURGE_AUTH");
          commit("SET_AUTH", data)
          dispatch('YEEKO_LOGIN_MAIL', data)
          //return data
          return resolve(data)
        })
    })
  },
  YEEKO_LOGIN_MAIL({ dispatch }) {
    //console.log("YEEKO_LOGIN_MAIL")
    return new Promise((resolve) => {
      dispatch("SET_HEADER")
      ApiService.post('/auth/facebook_mail/')
        .then(({ data }) => {
          //console.log('esto es data del yeekologin', data)
          if (data.facebook_error){
            dispatch("NOTIFY_USER", `Facebook no funciona ahora, 
              inténtalo más tarde`)
            return resolve(dispatch("HAS_NOT_LOGGED", 'facebookDown'))
          }
          else
            return resolve(dispatch("HAS_LOGGED", data))
        }).catch(err => {
          dispatch("NOTIFY_USER", `Tuvimos un problema con nuestros 
            servidores, por favor intenta iniciar sesión más tarde`);
          // eslint-disable-next-line no-console
          console.error(err)
          return resolve(dispatch("HAS_NOT_LOGGED", 'yeekoDown'))
        })
    })
  },
  YEEKO_LOGIN({ state, dispatch }, credentials) {
    return new Promise((resolve) => {
      if (credentials['status'] === 'connected'){
        let params = credentials["authResponse"]
        if (state.key_for_login)
          params.key = state.key_for_login
        const new_params = {
          uid: params.userID,
          token: params.accessToken,
          key: params.key
        }
        dispatch("SET_HEADER")
        ApiService.post('/auth/facebook/', new_params)
          .then(({ data }) => {
            //console.log('esto es data del yeekologin', data)
            if (data.facebook_error){
              dispatch("NOTIFY_USER", `Facebook no funciona ahora, 
                inténtalo más tarde`)
              return resolve(dispatch("HAS_NOT_LOGGED", 'facebookDown'))
            }
            else
              return resolve(dispatch("HAS_LOGGED", data))
          }).catch(err => {
            dispatch("NOTIFY_USER", `Tuvimos un problema con nuestros 
              servidores, por favor intenta iniciar sesión más tarde`);
            // eslint-disable-next-line no-console
            console.error(err)
            return resolve(dispatch("HAS_NOT_LOGGED", 'yeekoDown'))
          })
      }
      else
        return dispatch("HAS_NOT_LOGGED", 'statusDisconnected')
    })
  },
  CHECK_PSID(){
    return new Promise((resolve) => {
      (function(d, s, id){  
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.com/en_US/messenger.Extensions.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'Messenger'));
      let psid_cookie = cookies.get('psid')
      //console.log(!!psid_cookie)
      if (psid_cookie)
        return resolve({psid:psid_cookie})
      else
        window.extAsyncInit = function() {
          //console.log("asíncrono")
          // eslint-disable-next-line no-undef
          MessengerExtensions.getContext('778287658900971',
            function success({psid}) {
              cookies.set('psid', psid, "3d", null, TOKEN_SITE)
              return resolve({psid: psid})
            }, function error(err, errorMessage) {
              if (process.env.NODE_ENV === 'development'){
                let temp_psid = '2343105149084554'
                cookies.set('psid', temp_psid, "3d")
                return resolve({psid: temp_psid})
              }
              else{
                // eslint-disable-next-line no-console
                console.error(err + errorMessage)
                window.location.replace(`https://web.yeeko.org/registro/?next=${router.currentRoute.params.pathMatch}`);
                return resolve({})                
              }
            });
      };
    });
  },
  LOGIN_WEBVIEW({commit, dispatch}, psid){
    dispatch("SET_HEADER")
    ApiService.post('/messenger_login/', {uid: psid}).then(({data})=>{
      commit("PURGE_AUTH");
      commit("SET_AUTH", data)
      return {}
    })
  },
  CLOSE_WEBVIEW(){
    // eslint-disable-next-line no-undef
    MessengerExtensions.requestCloseBrowser(
      function success() {
        return true
      }, function error(err) {
        // eslint-disable-next-line no-console
        console.error(err)
        return false
      });
  },
  GET_CURRENT_USER({dispatch}) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      ApiService.get('/auth/facebook')
        .then(({data, status})=>{
          if (status !== 204) 
            return resolve(dispatch("HAS_LOGGED", data))
            //return dispatch("HAS_LOGGED", data)
          else
            return resolve(dispatch("HAS_NOT_LOGGED", 'Not Content(204)'))
            //return dispatch("HAS_NOT_LOGGED", 'Not Content(204)')
        })
        .catch(error => dispatch("HAS_NOT_LOGGED", `ServerError: ${error}`))
    })
  },
  LOGOUT({commit}) {
    //fbLogout().then((response) => {
    fbLogout().then(() => {
      window.FB.logout();
    }).catch(() => commit("NOTIFY_USER", 'false'))
    commit("PURGE_AUTH") // Elimina todo
    router.push({ name: 'Login' })
  },
  HAS_NOT_LOGGED({state, commit}, error_message) {
    //console.log(router.currentRoute.name)
    //console.log(state.is_logging_in)
    if (state.login_simple){
      commit("PURGE_AUTH");
      if (router.currentRoute.matched.findIndex(x=> x.name === 'Dashboard')>-1){
        commit("SET_NEXT_URL", true)
        let route_name = router.currentRoute.name
        if (route_name !== 'Login')
          router.replace({ name: 'Login' });
      }
    }
    else{
      commit("SET_LOGIN_SIMPLE", true);
    }
    commit("SET_IS_LOGGING_IN", false);
    // eslint-disable-next-line no-console
    console.error(error_message)
  },
  HAS_LOGGED({commit, dispatch, state}, userData) {
    commit("PURGE_AUTH");
    commit("SET_AUTH", userData)
    commit("SET_PAGES", userData)
    if (state.login_simple)
      dispatch("REDIRECT_LOGIN");
    else
      commit("SET_LOGIN_SIMPLE", true);
  },
  REDIRECT_LOGIN({state, commit}){
    let route_name = router.currentRoute.name
    if (route_name === 'Login' || route_name === 'Dashboard')
      router.push({ 
        path: state.next_url
          ? state.next_url 
          : '/dashboard/manage_pages'}),
      commit("SET_NEXT_URL", false);
  },
  UPDATE_USER_PAGES({state, commit},
    [linked_page_data, linked_page_id, demo_page_id = false]){
    // Creo una copia de los valores a modificar
    let pages_premium = [...state.user_details['pages_premium']]
    let available_pages = [...state.available_pages]
    // Elimina la duplicidad de página
    pages_premium
      .splice(pages_premium.findIndex(page => page['pid'] === linked_page_id), 1)
    // Si se manda un id de una página demo, se busca y elimina
    if (demo_page_id){
      pages_premium
        .splice(pages_premium.findIndex(page => page['pid'] === demo_page_id), 1)
    }
    //Elimino la página de disponibles para no volver a ser usada
    available_pages = available_pages
        .filter(pages => pages['pid'] !== linked_page_data['pid'])
    // Asigno los valores de la página ahora vinculada
    pages_premium.unshift(linked_page_data)
    // Envío los valores para actualizar el state
    commit("UPDATE_PAGE_IN_PAGES", [available_pages, pages_premium])
  },
  CREATE_PAGE_PREMIUM({dispatch}, pid) {
    return new Promise(resolve => {
      ApiService.post( `/page/${pid}/`, {})
        .then(({ data }) => {
          dispatch("UPDATE_USER_PAGES", [data, data.pid])
          return resolve(router.push(
            { name: 'PageHome', params: { pid: data.pid } }
          ))
        })
    })
  },

}

export default {
  state,
  actions,
  mutations,
  getters
}