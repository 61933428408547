/* global window, FB, document */
import Vue from 'vue';
import config from "@/common/config";

export function getFbLoginStatus() {
  console.log("FB", FB)
  console.log("window", window.FB)
  return new Promise(resolve => {
    try {
      FB.getLoginStatus(responseStatus => {
        resolve(responseStatus);
      });
    } catch (e) {
      window.FB.getLoginStatus(responseStatus => {
        resolve(responseStatus);
      });
    }
  });
}


export function fbLoginSimple() {
  return new Promise(resolve => {
    try {
      FB.login(response => resolve(response));
    } catch (e) {
      window.FB.login(response => resolve(response));
    }
  });
}

export function fbLoginOriginal(options) {
  return new Promise(resolve => {
    console.log("window", window)
    try {
      console.log("FB", FB)
    } catch (e) {
      console.log("error FB", e)
    }
    window.FB.login(response => resolve(response), options);
  });
}

export function fbLogin() {
  const real_perms = "pages_read_engagement,pages_messaging,pages_show_list"
  //const real_perms = "pages_read_engagement,pages_messaging,pages_show_list"
  //const real_perms = "whatsapp_business_messaging"
  //const real_perms = "pages_show_list"
  //const real_perms = "pages_read_engagement,pages_messaging"
  //const real_perms = "pages_read_engagement"
  //const real_perms = "pages_messaging"
  //const real_perms = "pages_show_list,pages_messaging"
  const options = {
    scope: real_perms,
    //return_scopes: true,
    //auth_type: "rerequest",
    enable_profile_selector: true,
    //profile_selector_ids: "120417672692079"
  }
  console.log(options)
  return new Promise(resolve => {
    window.FB.login(response => resolve(response), options);
  });
}

export function fbLoginBusiness() {
  const options = {
    config_id: "237932619279380",
    response_type: "code",
    override_default_response_type: true,
  }
  return new Promise(resolve => {
    window.FB.login(
      function(response) {
        console.log("response", response)
        resolve(response)
      },
      options
    );
  });
}


export function fbLoginPrev(options) {
  console.log(options)
  return new Promise(resolve => {
    window.FB.login(response => resolve(response), options);
  });
}

export function fbLogout() {
  return new Promise(resolve => {
    getFbLoginStatus().then(response => {
      if (response.status === 'connected') {
        window.FB.logout(response => resolve(response));
      }
    })
  });
}