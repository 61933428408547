<template>
  <content>
    <ManagePagesHeader v-if="!$route.query.logged_user"/>
    <v-card class="text-sm-center pa-4 mt-4 elevation-3">
      <h2>Define el nombre de tu Página Demo</h2>
      <v-form 
        v-model="demo_form" 
        ref="demo_name_form"
        @submit.prevent="createDemo" 
        lazy-validation
      >
        <v-text-field
          color="#DE006E"
          v-model="demo_title"
          :counter="70"
          @keyup.enter="createDemo"
          :rules="rules"
        ></v-text-field>
        <v-card-actions class="pr-0 mb-2">
          <v-flex class="text-xs-right mt-2 pb-0">
            <v-btn outlined @click="cancelDemo">Cancelar</v-btn>
            <v-btn
              class="accent"
              :disabled="!demo_form"
              @click="createDemo"
            >Crear demo</v-btn>
          </v-flex>
        </v-card-actions>
        <v-flex class="text-xs-center" mb-2 mt-3 v-if="show_fb_login_manage">
          <v-divider class="pb-3"></v-divider>
          <p>También puedes conectar Yeeko directamente a una de las páginas</p>
          <v-btn class="white--text" color="#4267B2" @click="asignPermits">
            <v-icon left size="22" dark>
              fab fa-facebook-square
            </v-icon>
            Otorgar permisos
          </v-btn>
        </v-flex>
      </v-form>
      <!-- <v-card-actions class="pr-0 mb-2">
        <v-flex class="text-sm-right mt-2 pb-0">
          <v-btn outlined @click="cancelDemo">Cancelar</v-btn>
          <v-btn
            class="accent"
            :disabled="!demo_form"
            @click="createDemo"
          >Crear demo</v-btn>
        </v-flex>
      </v-card-actions> -->
      <v-flex class="text-sm-center" mb-2 mt-3 v-if="show_fb_login_manage">
        <v-divider class="pb-3"></v-divider>
        <p>También puedes conectar Yeeko directamente a una de las páginas</p>
        <v-btn class="white--text" color="#4267B2" @click="asignPermits">
          <v-icon left size="22" dark>
            fab fa-facebook-square
          </v-icon>
          Otorgar permisos
        </v-btn>
      </v-flex>
    </v-card>
  </content>
</template>

<script>
import ManagePagesHeader from "@/components/manage_pages/ManagePagesHeader.vue";
import router from '@/router'
import { fbLogin } from "@/components/dashboard/login/helpers";
import { mapState } from "vuex";
export default {
  name: "CreateDemo",
  components: {
    ManagePagesHeader,
  },
  data() {
    return {
      rules: [
        v =>
          (v && v.length > 0) ||
          "El nombre de página demo no puede estar en blanco",
        v =>
          (v && v.length <= 70) ||
          "El nombre de página demo no puede ser mayor a 70 caracteres"
      ],
      demo_form: false,
      demo_title: "",
      show_fb_login_manage: false,
      app_id: process.env.VUE_APP_FACEBOOK_ID
      // app_id: "778287658900971"
    };
  },
  methods: {
    createDemo() {
      console.log("createDemo")
      if (this.$refs.demo_name_form.validate()){
        this.$store.dispatch("CREATE_DEMO_PAGE", { title: this.demo_title })
          .then((data) => {
            this.$store.dispatch("NOTIFY_USER", "Página demo creada correctamente")
            this.$store.commit("UPDATE_PAGES_PREMIUM", data)
            return data
          })
          .then((data) => {
            router.push({
              path: `/dashboard/${data["pid"]}/home`
            });
          })
          .catch(error =>
            this.$store.dispatch(
              "NOTIFY_USER",
              `Hubo un problema al crear la página demo. Error: ${error}`
            )
          );
      }
      else
        console.log("HOLA NO")
    },
    cancelDemo() {
      router.push({ path: '/dashboard/manage_pages' });
    },
    asignPermits() {
      fbLogin().then(response => {
        this.$store.dispatch("ASIGN_PERM_FACEBOOK", response);
      });
    }
  },
  computed: {
    ...mapState({
      available_pages: state => state.auth.available_pages
    })
  },
  mounted(){
    if (router.currentRoute.query.demo == "true")
      this.show_fb_login_manage = true
  }
};
</script>

<style lang="scss" scoped>
</style>