import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'
import auth from "./auth"
import page_data from "./page_data"
import current_page from "./current_page"
import yks from "./yks"
import brd from "./brd"
import common from "./common"
import users from "./users"
import test from "./test"
import camps from "./camps"
import results from "./results"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    page_data,
    common,
    current_page,
    yks,
    brd,
    users,
    test,
    camps,
    results,
  },
  plugins: [createPersistedState({
    paths: [
      'auth.auth_yeeko', 
      'auth.is_authenticated',
      'auth.next_url',
      'auth.is_logged',
      'auth.user_details',
      'auth.available_pages',
      'auth.has_available_pages',
      'auth.facebook_pages_count',
    ]
  })]
})