/*eslint-disable*/
import router from '@/router'
import axios from "axios";
import JwtService from "@/common/jwt.service";
import * as d3Array from 'd3-array';
import * as d3 from 'd3';

let request = axios.CancelToken.source();

let pid = (given_pid) => given_pid || router.currentRoute.params.pid

console.log("process.env", process.env)
let ApiService = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: { "Content-Type": "application/json" }
});

let ApiTest = axios.create({
  baseURL: 'https://api.yeeko.org/api-rest',
  headers: {
    "Content-Type": "application/json",
    "Authorization": 'Token '
  }
});

const state = {
  cats: {},
};

const getters = {
  all_circles(state, rootState) {
    if (!state.cats.circles || !state.cats.circle_types)
      return undefined 
    let aux_count = 0
    let circles = state.cats.circles.map(cir=>{
      if (cir.circle_type.name === 'aux_bot')
        aux_count++
      cir.is_aux = cir.circle_type.default_hide
      return cir
    })
    let few_aux  = aux_count <= 3
    let ord_circles = circles.slice().sort((a,b)=>
      d3.ascending(a.circle_type.order, b.circle_type.order))
    let grouped = d3Array.group(ord_circles, d=> d.circle_type.id)
    let grouped2 = Array.from(grouped)
    let grouped3 = grouped2.reduce((coll, type)=>{
      let circle_obj = state.cats.circle_types.find(cir=>cir.id === type[0])
      let header = {'header': `Círculos ${circle_obj.plural}:`}
      if (few_aux)
        header.is_aux = circle_obj.order >= 4
      coll.push(header)
      return [...coll, ...type[1]]
    },[])
    return {groups: grouped3, few_aux: few_aux, circles: state.cats.circles}
  },
  all_extras(state) {
    if (!state.cats.extra_types || !state.cats.page_extras)
      return undefined 
    let aux_count = 0
    let toStandard = (str) => str.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "").toLowerCase()
    //let xtra_aux = state.cats.extra_types.find(xtra=>
    //  xtra.name == 'auxiliar_auto')
    let xtras_dash = state.cats.page_extras/*.filter(xtra=>
       !xtra.extra_type.only_developers)*/
    let xtras_dash2 = xtras_dash.map(xtra=>{
      if (!!xtra.extra_type.default_hide)
        aux_count++
      xtra.name_norm = toStandard(xtra.name)
      return xtra
    })
    let few_aux = aux_count <= 3
    //let ord_extras = xtras_dash.slice().sort((a,b)=>
    //  d3.ascending(a.circle_type_obj.order, b.circle_type_obj.order))
    //let grouped = d3Array.group(ord_extras, d=> d.circle_type_obj)
    let grouped = d3Array.group(xtras_dash2, d=> d.extra_type.id)
    //console.log(grouped)
    let grouped2 = Array.from(grouped)
    let grouped3 = grouped2.reduce((coll, type)=>{
      let xtra_type = state.cats.extra_types.find(xt=>xt.id === type[0])
      const name_header = xtra_type.public_name || `Extras ${xtra_type.name}`
      let header = {header: `${name_header}:`, extra_type: xtra_type}
      return [...coll, header, ...type[1]]
    }, [])
    console.log({
      groups: grouped3,
      few_aux: few_aux,
      extras: state.cats.page_extras,
      extras_dash: xtras_dash2
    })
    return {
      groups: grouped3,
      few_aux: few_aux,
      extras: state.cats.page_extras,
      extras_dash: xtras_dash2
    }
  },
  message_types(state){
    return state.cats.message_types.map(mt=>
      ({...mt, ...(mt.dashboard_extension || { icon: 'message' })}))
  },
  extra_types: (state) => state.cats.extra_types || [],
  default_extra(state){
    if (!state.cats.extra_types)
      return []
    return state.cats.extra_types.find(et=> et.name === 'auxiliar_auto')
  },
  available_roles(state){
    if (!state.cats.roles)
      return []
    return state.cats.roles.filter(rol=>rol.is_default)
  },
  circles(state){
    return state.cats.circles || []
  },
  circle_types(state){
    return state.cats.circle_types || []
  },
};

const mutations = {
  SET_CATALOGS(state, cats){
    state.cats = {...state.cats, ...cats};
  },
  ADD_CIRCLE(state, new_circle){
    //console.log(new_circle)
    state.cats.circles.push(new_circle)
  },
  ADD_NEW_EXTRA(state, new_extra) {
    state.cats.page_extras.push(new_extra);
  },
  UPDATE_EXTRA(state, extra_data) {
    if (!state.cats.page_extras)
      return
    const extra_idx = state.cats.page_extras.findIndex(xtra=>
      xtra.id === extra_data.id)
    state.cats.page_extras.splice(extra_idx, 1, extra_data);
  },
};

const actions = {
  SET_HEADER({state}){
    let token = JwtService.getToken()
    if (token){
      ApiService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
    //this.$axios.setToken(token, 'Token')
  },  
  FETCH_NEW_CATALOGS({commit, dispatch, state}, [cat_list=[], forced=false]){
    let cats = cat_list.reduce((array, cat)=>{
      if (!state[cat] || forced)
        array.push(cat)
      return array
    },[])
    console.log("pid", pid())
    console.log("router.currentRoute", router.currentRoute)
    let compl = cats.join(",")
    compl = compl ? `?cats=${compl}` : ''
    return new Promise (resolve => {
      dispatch("SET_HEADER")
      ApiService.get(`page/${pid()}/catalogs/${compl}`).then(({data})=>{
        commit('SET_CATALOGS', data);
        return resolve(data)
      }).catch((err)=>{
        console.log(err)
      })
    })
  },
  CREATE_CIRCLE({commit, dispatch}, data_new_circle) {
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService.post(`/page/${pid()}/circle/`, data_new_circle)
        .then(({data}) => {
          commit('ADD_CIRCLE', data)
          return resolve(data)
        })
        .catch(error=>{
          console.log(error)
          //return resolve(error.response.data)
        })
    })
  },  
  SAVE_EXTRA({state, commit, dispatch}, data_extra) {
    let id = data_extra.id
    let post_url = id ? `${id}/` : ''
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService[id ? 'put' : 'post'](
          `page/${pid()}/extra_config/${post_url}`, data_extra)
        .then(({data}) => {
          //console.log(data)
          if (state.cats.page_extras)
            commit(id ? 'UPDATE_EXTRA' : 'ADD_NEW_EXTRA', data);
          else
            commit(id ? 'UPDATE_RES_EXTRA' : 'ADD_RES_NEW_EXTRA', data, {root: true});
          return resolve(data)
        })
        .catch(error=>{
          console.log(error)
          return resolve({error: error.response})
        })
    })
  },
  SET_HEADER_TEST({state}){
    if (token){
      ApiService.defaults.headers.common['Authorization'] = `Token ${token}`;
    }
    //this.$axios.setToken(token, 'Token')
  },
  FETCH_HEAVY({commit, dispatch}, [params='', table='user']) {
    return new Promise(resolve => {
      dispatch('SET_HEADER')
      ApiService.get(`/page/${pid()}/${table}/${params}`,
        { cancelToken: request.token })
        .then(({data}) => {
          return resolve(data)
        })
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            request = null
            request = axios.CancelToken.source();
            // return resolve({cancel: true})
          } 
          else 
            console.log("error al cancelar??")
        });
    })
  },
  CANCEL_FETCH({commit, dispatch}) {
    request.cancel("Optional message")
  },
  GET_TRACKING({commit, dispatch}, tracking_id){
    return new Promise (resolve => {
      //dispatch("SET_HEADER_TEST")
      dispatch("SET_HEADER")
      ApiService.get(`/page/${pid()}/tracking/${tracking_id}/`).then(({data})=>{
      //ApiTest.get(`/page/${pid()}/tracking/2158/`).then(({data})=>{
        return resolve(data)
      }).catch((err)=>{
        console.log(err)
      })
    })
  },
  GET_CONVERSATION({commit, dispatch}, um_id){
    return new Promise (resolve => {
      //dispatch("SET_HEADER_TEST")
      dispatch("SET_HEADER")
      ApiService.get(`/page/${pid()}/conversation/${um_id}/`).then(({data})=>{
      //ApiTest.get(`/page/${pid()}/tracking/2158/`).then(({data})=>{
        return resolve(data)
      }).catch((err)=>{
        console.log(err)
      })
    })
  },
  UPDATE_TRACKING_MESSAGE({ dispatch }, [tracking_id, status]) {
    return new Promise(resolve => {
      dispatch("SET_HEADER")
      // ApiService.put(`/facebook/tracking/${tracking_id}/`, { status: status })
      ApiService.put(`/page/${pid()}/tracking/${tracking_id}/change_status/`,
          { status: status })
        .then(({data})=> {
          return resolve(data)
        })
    })
  },
  SEND_PERSON_MESSAGE({commit, dispatch}, [user_id, message]){
    return new Promise (resolve => {
      dispatch("SET_HEADER")
      //ApiTest.post(`/page/${pid()}/user/12644/send_message/`, message)
      ApiService.post(`/page/${pid()}/user/${user_id}/send_message/`, message)
      .then(({data})=>{
        //api-rest/page/pid/user/user_id/send_message/
          //commit("SET_YEEKO", data)
         console.log(data)
         return resolve(data)
      }).catch((err)=>{
        console.log(err)
      })
    })
  },
  SEND_PERSON_TEMPLATE({commit, dispatch}, [user_id, message]){
    return new Promise (resolve => {
      dispatch("SET_HEADER")
      ApiService.post(`/page/${pid()}/user/${user_id}/send_template/`, message)
      .then(({data})=>{
         console.log(data)
         return resolve(data)
      }).catch((err)=>{
        console.log(err)
      })
    })
  },


};


export default {
  state,
  getters,
  actions,
  mutations,
}