<template>
  <!-- <article id="pdf-holder" class="font--text yeeko-create__container" type='application/pdf'></article> -->
  <div>
    <LandingNav/>
    <v-container fluid grid-list-md pa-0 pt-3 mt-5 class="pdf-viewer">
      <div id="pdf-holder"></div>

    </v-container>

  </div>
  
</template>

<script>
import LandingNav from '@/components/landing/LandingNav.vue'

import PDFObject from 'pdfobject'
export default {
  name: "Policy",
  components: {
    LandingNav
  },
  data() {
    return {
      policy_pdf: 'https://cdn-yeeko.s3-us-west-2.amazonaws.com/Aviso-Privacidad-Yeeko'
    }
  },
  mounted() {
    PDFObject.embed(this.policy_pdf, "#pdf-holder")
  }
};
</script>

<style lang="scss" scoped>
.pdf-viewer {
  // min-height: 90vh;
  // border: 1rem solid rgba(0, 0, 0, 0.1);
  #pdf-holder {
    height: 90vh;
  }
  
}
</style>